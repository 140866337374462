import { Suspense, useEffect, useState } from "react";
import { motion } from "framer-motion";
import sanityClient from "../client";

import { Canvas, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
// import { OrbitControls } from "@react-three/drei";
import CameraControls from "../components/CameraControls";
import Plane from "../components/Plane";
import Lights from "../components/Lights";

import Loading from "../components/Loading";
import ProductGridItem from "../components/ProductGridItem";
import Footer from "../components/Footer";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkWidth();
    window.addEventListener("resize", checkWidth, false);
  });

  const maps = [
    // useLoader(TextureLoader, "./assets/textures/01_map.png"),
    // useLoader(TextureLoader, "./assets/textures/02_map.png"),
    // useLoader(TextureLoader, "./assets/textures/03_map.png"),
    // useLoader(TextureLoader, "./assets/textures/04_map.png"),
    useLoader(TextureLoader, "./assets/textures/01_map.png"),
    useLoader(TextureLoader, "./assets/textures/05_map.png"),
    useLoader(TextureLoader, "./assets/textures/04_map.png"),
  ];

  const displacementMaps = [
    useLoader(TextureLoader, "./assets/textures/01_disp.png"),
    useLoader(TextureLoader, "./assets/textures/05_disp.png"),
    useLoader(TextureLoader, "./assets/textures/04_disp.png"),
  ];

  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "product"]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        key={"home-wrapper"}
      >
        <section className="home__canvas-wrapper">
          <Canvas flat shadows>
            <Suspense>
              <Lights />
              <Plane
                displacementMaps={displacementMaps}
                maps={maps}
                isMobile={isMobile}
              />
              <CameraControls isMobile={isMobile} />
              {/* <OrbitControls /> */}
            </Suspense>
          </Canvas>
        </section>


        <section className="home__product-grid">
          {pageData &&
            pageData.map((product, index) => {
              return <ProductGridItem {...{ product }} key={index} />;
            })}
        </section>
        <Footer />
      </motion.main>
    );
  } else {
    return <Loading />;
  }
};

export default Home;
