import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../hooks/useProgressiveImage.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Image = (props) => {
    const { imageSrc, classes, alt } = props;

    const smallUrl = `${urlFor(imageSrc)}?q=10`;
    const largeUrl = `${urlFor(imageSrc)}`;
    const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <img
    src={src}
    alt={`FLORENCE HARPER - ${alt}`}
    className={classes}
    style={{
      filter: blur ? "blur(20px)" : "none",
      transition: blur ? "none" : "filter 0.3s ease-out",
    }}
  />
  )
}

export default Image