import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { degToRad } from "three/src/math/MathUtils";

const Plane = ({ displacementMaps, loopDuration = 3000, maps, isMobile }) => {
  const { viewport } = useThree();
  const meshRef = useRef();
  const displacementIndex = useRef(0);
  // const displacementScale = useRef(0.2);

  // const [isMobile, setIsMobile ] = useState(false);
  // useEffect(()=>{

  //   const checkWidth = () => {
  //     if(window.innerWidth < 750){
  //       setIsMobile(true)
  //     } else {
  //       setIsMobile(false)
  //     }
  //   }
  //   checkWidth();
  //   window.addEventListener('resize', checkWidth, false);
  //   console.log(isMobile);
  // });

  useEffect(() => {
    const animateDisplacement = () => {
      displacementIndex.current =
        (displacementIndex.current + 1) % displacementMaps.length;
      meshRef.current.material.displacementMap =
        displacementMaps[displacementIndex.current];
      meshRef.current.material.map = maps[displacementIndex.current];
 
    };

    setInterval(() => {
      animateDisplacement();
    }, 3000);
  }, [displacementMaps, loopDuration]);

  // useFrame(() => {
  //   if (meshRef.current) {
  //     meshRef.current.material.displacementScale = displacementScale.current;
  //   }
  // });

  return (
    <mesh ref={meshRef} castShadow receiveShadow rotation={[0, 0, isMobile?(degToRad(-90)):(0)]} position={[0, 0, isMobile?(-4):(0)]}>

      {isMobile?(
        <planeGeometry args={[viewport.height + 4, (viewport.height + 4) * 9 / 16, 1000, 1000]} />
      ):(
        <planeGeometry args={[viewport.height * 2, (viewport.height * 9 / 16) * 2, 1000, 1000]} />
      )}
      <meshStandardMaterial
        attach="material"
        displacementMap={displacementMaps[0]}
        displacementScale={0.03}
        map={maps[0]}
      />
    </mesh>
  );
};

export default Plane;
