import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../hooks/useProgressiveImage.js";
// import { useEffect } from "react";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ProductGridItem = (props) => {
  const { product } = props;

  const smallUrl = `${urlFor(product.mainImage)}?q=10`;
  const largeUrl = `${urlFor(product.mainImage)}`;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <a className="home__grid-item" href={`/products/${product.slug.current}`}>
      <img
        src={src}
        alt={`FLORENCE HARPER - ${product.title}`}
        className="home__grid-image"
        style={{
          filter: blur ? "blur(20px)" : "none",
          transition: blur ? "none" : "filter 0.3s ease-out",
        }}
      />
      <div className="home__grid-title">
        {product.title}
      </div>
    </a>
  );
};

export default ProductGridItem;
