import { useEffect, useRef } from "react";

// import './BuyButton.scss';

const BuyButton = (props) => {
  const { productData, ui } = props;
  const node = useRef();


  useEffect(() => {
    if (node && productData) {
        console.log(productData.shopifyId);
        ui.createComponent("product", {
            id: productData.shopifyId,
            node: node.current,
            moneyFormat: '£{{amount}}',
            options: {
              product: {
                buttonDestination: "cart",
                iframe: false,
                contents: {
                    // title: false,
                    description: true,
                    // quantity: false,
                    img: false,
                    variantTitle: false,
                    price: true
                },
                text: {
                    button: 'ADD TO CART'
                }
              },
              // toggle: {
              //   iframe: false,
              //   styles:{
              //     toggle:{
              //       'display': 'none'
              //     }
              //   }
              // },
            },
          });
        }
      }, [ui, productData]);

  return (
    <div className="buy-button__wrapper" >
      <div ref={node} />
    </div>
    
    );
};

export default BuyButton;
