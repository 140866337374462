import { useRef } from "react";
import { useThree } from "@react-three/fiber";
const Lights = () => {
    
  const lightRef = useRef(null);
  const camRef = useRef(null);
//   useHelper(lightRef, DirectionalLightHelper, 1,  "red");
//   useHelper(camRef, CameraHelper, 1,  "red");

  const { viewport } = useThree();

  return (
    <>
      <directionalLight
        castShadow
        position={[(viewport.width / 2), (viewport.height / 2), 10]}

        shadow-mapSize={[512, 512]}
        shadow-bias={0.0003}
        ref={lightRef}
        intensity={1}
      >
        <orthographicCamera 
        ref={camRef}
        attach="shadow-camera" 
        args={[-13, 13, 50, -50]} 
        near={0.1} 
        far={100} 
        />
      </directionalLight>


      {/* <ambientLight intensity={0.01} /> */}
 
    </>
  );
};

export default Lights;
