import { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import Home from "./views/Home";
import Product from "./views/Product";
import Info from "./views/Info";

import Client from 'shopify-buy';
import ShopifyBuy from "@shopify/buy-button-js";

import "./styles/global-styles.scss";
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();

  const [shopifyClient, setShopifyClient] = useState({});
  const [ui, setUI] = useState({});

  useEffect(() => {
    // const shopifyClientInstance = ShopifyBuy.buildClient({
    //   domain: "048865-4.myshopify.com",
    //   storefrontAccessToken: "f285968a479f09c10a3c0e9d4e7ab873",
    // });

    const client = Client.buildClient({
      domain: "048865-4.myshopify.com",
      storefrontAccessToken: "f285968a479f09c10a3c0e9d4e7ab873",
    });

    setShopifyClient(client);

    const uiInstance = ShopifyBuy.UI.init(client);
    setUI(uiInstance);
  }, []);


  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  return (
    <>
    <AnimatePresence>
      <a href={"/"} className="header__logo-wrapper">
        <img
          src="/assets/FLORENCE-HARPER.svg"
          alt="FLORENCE HARPER"
          className="header__logo"
        />
      </a>
      {/* <a href="/info" className="header__info">
        INFO
      </a> */}
      
      <Routes location={location} key={location.pathname}>
        <Route element={<Home key={"Home"} />} path="/" />
        <Route element={<Product key={"Product"} 
        shopifyClient={shopifyClient} ui={ui}
        />} path="/products/:slug" />
        <Route element={<Info key={"Info"} />} path="/info" />
      </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
