import { useState } from 'react'
import { useThree, useFrame } from "@react-three/fiber";
import { PerspectiveCamera } from '@react-three/drei';

const CameraControls = (props) => {

  const { isMobile } = props;

  const { camera, mouse } = useThree();
  const EasingFactor = 0.05;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useFrame(() => {
    if(!isMobile){
      const targetX = (mouse.x / window.innerWidth) * 100;
      const targetY = (mouse.y / window.innerHeight) * 100;
  
      const newLeft = (targetX * 2) + 0;
      const newTop = (targetY * 2) + 0;
  
      const dx = newLeft - position.x;
      const dy = newTop - position.y;
  
      const nextPosition = {
        x: position.x + dx * EasingFactor,
        y: position.y + dy * EasingFactor,
      };
  
      setPosition(nextPosition);
      camera.lookAt(0, 0, 0);
      // console.log(camera.position);
    }

  });

  return  <PerspectiveCamera makeDefault position={[position.x, position.y, 1]} />

};

export default CameraControls;
