import { useState, useEffect } from "react";

const Footer = () => {
  const [date, setDate] = useState(2023);

  useEffect(() => {
    const year = new Date().getFullYear();
    setDate(year);
  }, []);

  return (
    <footer className="footer__wrapper">
      <div className="footer__text">
        ALL CONTENT &copy; FLORENCE HARPER {date}
      </div>
    </footer>
  );
};

export default Footer;
