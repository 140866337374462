import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import sanityClient from "../client";
import { useParams } from "react-router-dom";

import Image from "../components/Image";
import BuyButton from "../components/BuyButton";
import Loading from "../components/Loading";
import Footer from "../components/Footer";

const Product = (props) => {
  const { shopifyClient, ui } = props;
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);
  const [noProjectExists, setNoProjectExists] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
        ...,
      }`,
        { slug }
      )
      .then((data) => {
        setPageData(data[0]);
        if (data.length === 0) {
          setNoProjectExists(true);
        }
      })
      .catch();
    if (pageData) {
      console.log(pageData.productImages);
    }
  }, [slug]);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        key={"product-wrapper"}
      >
        <a href={"/"} className="header__back">
          BACK
        </a>
        {pageData.productImages &&
          pageData.productImages.map((image, index) => {
            return (
              <div className="product__main-image-wrapper" key={index}>
                <Image
                  imageSrc={image}
                  classes={"product__main-image"}
                  alt={`FLORENCE HARPER - ${pageData.title}`}
                />
              </div>
            );
          })}
        <div className="product__main-image-wrapper">
          <Image
            imageSrc={pageData.mainImage}
            classes={"product__main-image"}
            alt={`FLORENCE HARPER - ${pageData.title}`}
          />
        </div>
        <BuyButton
          shopifyClient={shopifyClient}
          ui={ui}
          productData={pageData}
        />
        <Footer />
      </motion.main>
    );
  } else if (noProjectExists === true) {
    return <Loading />;
  }
};

export default Product;
